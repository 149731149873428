<template>
  <smooth-reflow v-if="partners" class="partners flex" tag="ul">
    <li v-for="(partner, key) in partners" :key="key" class="full half-500 third-800 animated fadeIn">
      <a :href="partner.url.url" target="_blank" rel="noopener noreferrer">
        <strong>{{ partner.name | quotes }}</strong>
      </a>
      <br />
      <span class="location">
        <span v-if="partner.city">{{ partner.city }}, </span>
        <span v-if="partner.state">{{ partner.state }}</span>
      </span>
    </li>
  </smooth-reflow>
</template>

<script>
export default {
  name: 'PartnersList',
  props: {
    partners: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.partners
  justify-content center
  align-items center
  margin 0
  padding 0
  list-style-type none

  &.flex
    margin 0
    padding 0
    width 100%

  li
    padding 0.5em
    text-align center

    .location
      font-size 0.7em
</style>
