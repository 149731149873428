<template>
  <transition-group id="nav-toggle" name="zoom" tag="div">
    <inline-svg
      v-if="!showNav"
      key="menu"
      :src="menu"
      width="2em"
      height="1.4em"
      fill="#fff"
      class="menu"
      @click="toggleNav"
    ></inline-svg>
    <inline-svg
      v-if="showNav"
      key="close"
      :src="close"
      width="2em"
      height="1.4em"
      fill="#3474d9"
      class="close"
      @click="toggleNav"
    ></inline-svg>
  </transition-group>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import menu from '../assets/icons/menu.svg'
import close from '../assets/icons/close.svg'

export default {
  name: 'NavToggle',
  data() {
    return {
      menu,
      close
    }
  },
  computed: {
    ...mapState('ui', ['showNav'])
  },
  methods: {
    ...mapActions('ui', ['toggleNav'])
  }
}
</script>

<style lang="stylus" scoped>
#nav-toggle
  position fixed
  top 1em
  right 1em
  z-index 1001
  width 1.6em
  height 1.6em
  cursor pointer

  svg
    position absolute

  .menu
    filter drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.5))
</style>
