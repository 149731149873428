import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Alert from '../views/Alert.vue'
import Opportunity from '../views/Opportunity.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/alerts/:id',
    name: 'alerts',
    component: Alert
  },
  {
    path: '/opportunities/:id',
    name: 'opportunities',
    component: Opportunity
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
