require('../node_modules/picnic/picnic.min.css')
require('../node_modules/vue2-animate/dist/vue2-animate.css')
require('../node_modules/webkul-micron/dist/css/micron.css')

import Vue from 'vue'
import App from './App.vue'
import plugins from './app/plugins'
import router from './app/router'
import store from './app/store'
import utils from './app/utils'
import './app/sw'

plugins(Vue)
utils(Vue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
