<template>
  <smooth-reflow v-show="alert" id="alert" v-resize:debounce="setHeight" tag="main">
    <figure v-if="image != stdImg">
      <img :src="image" alt="" />
    </figure>
    <div v-if="alert" class="content">
      <div v-if="title" v-html="title"></div>
      <div v-if="alert.date" class="date">
        {{ alert.date | date }}
      </div>
      <div v-if="content" v-html="content"></div>
      <div v-if="alert['call-to-action'] && alert.url" class="cta">
        <a :href="alert.url.url" target="_blank">
          <button type="button">{{ alert['call-to-action'] }}</button>
        </a>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

export default {
  name: 'Alert',
  data() {
    return {
      alert: null,
      stdImg: 'https://www.jewishearthalliance.org/img/jea_screenshot.png'
    }
  },
  computed: {
    ...mapState({
      alerts: state => state.alerts.content
    }),
    title() {
      return this.alert && this.alert.title ? this.toHtml(this.alert.title) : null
    },
    titleSafe() {
      return this.alert && this.alert.title ? this.alert.title[0].text : null
    },
    content() {
      return this.alert && this.alert.content ? this.toHtml(this.alert.content) : null
    },
    image() {
      return this.alert && this.alert.image && this.alert.image.url ? this.alert.image.url : this.stdImg
    },
    summary() {
      return this.alert && this.alert.short_description ? this.alert.short_description[0].text : null
    }
  },
  async mounted() {
    if (!this.alerts.length) await this.getAlerts(this)
    if (this.alerts.length) {
      this.alert = this.alerts.filter(alert => {
        return alert.uid === this.$route.params.id
      })[0].data
      this.setHeight()
      setTimeout(() => this.$scrollTo('#app'), 500)
    }
  },
  methods: {
    ...mapCacheActions('alerts', ['getAlerts']),
    toHtml(content) {
      return this.$utils.toHtml(content)
    },
    setHeight() {
      setTimeout(() => this.$utils.setHeight('#alert'), 400)
    }
  },
  metaInfo() {
    return {
      title: this.titleSafe,
      link: [
        {
          rel: 'canonical',
          href: `https://www.jewishearthalliance.org${this.$route.path}`
        }
      ],
      meta: [
        {
          property: 'title',
          content: this.titleSafe
        },
        {
          property: 'description',
          content: this.summary
        },
        {
          property: 'og:url',
          content: `https://www.jewishearthalliance.org${this.$route.path}`
        },
        {
          property: 'og:title',
          content: this.titleSafe
        },
        {
          property: 'og:description',
          content: this.summary
        },
        {
          property: 'og:image',
          content: this.image
        },
        {
          property: 'twitter:url',
          content: `https://www.jewishearthalliance.org${this.$route.path}`
        },
        {
          property: 'twitter:title',
          content: this.titleSafe
        },
        {
          property: 'twitter:description',
          content: this.summary
        },
        {
          property: 'twitter:image',
          content: this.image
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
#alert
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  min-height 80vh
  width 100%

  figure
    margin 4em 0 -8em

    img
      transform rotate(-2.5deg) !important

  .content
    z-index 200
    margin 4em auto

    h1
      color #319a46
      text-align center

    .date
      color #aaa

    .cta
      padding 2em 0
</style>
