<template>
  <smooth-reflow v-show="opportunity" id="opportunity" v-resize:debounce="setHeight" tag="main">
    <figure v-if="image != stdImg">
      <img :src="image" alt="" />
    </figure>
    <div v-if="opportunity" class="content">
      <div v-if="title" v-html="title"></div>
      <div v-if="opportunity.date" class="date">
        {{ opportunity.date | date }}
      </div>
      <div v-if="content" v-html="content"></div>
      <div v-if="opportunity['call-to-action'] && opportunity.url" class="cta">
        <a :href="opportunity.url.url" target="_blank">
          <button type="button">{{ opportunity['call-to-action'] }}</button>
        </a>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

export default {
  name: 'Opportunity',
  data() {
    return {
      opportunity: null,
      stdImg: 'https://www.jewishearthalliance.org/img/jea_screenshot.png'
    }
  },
  computed: {
    ...mapState({
      opportunities: state => state.opportunities.content
    }),
    title() {
      return this.opportunity && this.opportunity.title ? this.toHtml(this.opportunity.title) : null
    },
    titleSafe() {
      return this.opportunity && this.opportunity.title ? this.opportunity.title[0].text : null
    },
    content() {
      return this.opportunity && this.opportunity.content ? this.toHtml(this.opportunity.content) : null
    },
    image() {
      return this.opportunity && this.opportunity.image && this.opportunity.image.url
        ? this.opportunity.image.url
        : this.stdImg
    },
    summary() {
      return this.opportunity && this.opportunity.short_description ? this.opportunity.short_description[0].text : null
    }
  },
  async mounted() {
    if (!this.opportunities.length) await this.getOpportunitys(this)
    if (this.opportunities.length) {
      this.opportunity = this.opportunities.filter(opportunity => {
        return opportunity.uid === this.$route.params.id
      })[0].data
      this.setHeight()
      setTimeout(() => this.$scrollTo('#app'), 500)
    }
  },
  methods: {
    ...mapCacheActions('opportunities', ['getOpportunitys']),
    toHtml(content) {
      return this.$utils.toHtml(content)
    },
    setHeight() {
      setTimeout(() => this.$utils.setHeight('#opportunity'), 400)
    }
  },
  metaInfo() {
    return {
      title: this.titleSafe,
      link: [
        {
          rel: 'canonical',
          href: `https://www.jewishearthalliance.org${this.$route.path}`
        }
      ],
      meta: [
        {
          property: 'title',
          content: this.titleSafe
        },
        {
          property: 'description',
          content: this.summary
        },
        {
          property: 'og:url',
          content: `https://www.jewishearthalliance.org${this.$route.path}`
        },
        {
          property: 'og:title',
          content: this.titleSafe
        },
        {
          property: 'og:description',
          content: this.summary
        },
        {
          property: 'og:image',
          content: this.image
        },
        {
          property: 'twitter:url',
          content: `https://www.jewishearthalliance.org${this.$route.path}`
        },
        {
          property: 'twitter:title',
          content: this.titleSafe
        },
        {
          property: 'twitter:description',
          content: this.summary
        },
        {
          property: 'twitter:image',
          content: this.image
        }
      ]
    }
  }
}
</script>

<style lang="stylus">
#opportunity
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  min-height 80vh
  width 100%

  figure
    margin 4em 0 -8em

    img
      transform rotate(-2.5deg) !important

  .content
    z-index 200
    margin 4em auto

    h1
      color #319a46
      text-align center

    .date
      color #aaa

    .cta
      padding 2em 0
</style>
