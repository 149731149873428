<template>
  <smooth-reflow v-if="allies" class="allies flex" tag="ul">
    <li v-for="(ally, key) in allies" :key="key" class="full half-500 third-800">
      <a :href="ally.url.url" target="_blank" rel="noopener noreferrer">
        <strong>{{ ally.name | quotes }}</strong>
      </a>
    </li>
  </smooth-reflow>
</template>

<script>
export default {
  name: 'AlliesList',
  props: {
    allies: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.allies
  justify-content center
  align-items center
  margin 0
  padding 0
  list-style-type none

  &.flex
    margin 0
    padding 0
    width 100%

  li
    padding 0.5em
    text-align center
</style>
