<template>
  <smooth-reflow v-if="leadership" id="leadership" v-resize:debounce="setHeight" :tag="`section`">
    <figure v-if="leadership.image">
      <img :src="leadership.image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>Leadership</h2>
      <div v-if="leadership.content" v-html="leadership.content"></div>
      <leadership-list :leadership="leaderData"></leadership-list>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

import LeadershipList from './LeadersList.vue'

export default {
  name: 'Leadership',
  components: {
    LeadershipList
  },
  computed: {
    ...mapState('content', ['leadership']),
    ...mapGetters('leadership', ['leaderData'])
  },
  created() {
    this.getLeadership(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('leadership', ['getLeadership']),
    showMore() {
      this.more = !this.more
    },
    setHeight() {
      this.$utils.setHeight('#leadership')
    }
  }
}
</script>

<style lang="stylus" scoped>
#leadership
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0

    h3
      margin-bottom 1em
      padding-bottom 0.25em
      border-bottom 5px solid #319a46
      color #319a46
      text-align center
      text-transform uppercase

    .more
      display flex
      justify-content center
      align-items center
      margin-bottom 1em
      width 100%
      font-size 0.8em
</style>
