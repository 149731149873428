<template>
  <smooth-reflow v-if="resources" id="resources" tag="section">
    <figure v-if="resources.image">
      <img :src="resources.image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>Resources</h2>
      <div v-if="resources.content" v-html="resources.content"></div>
      <ul v-if="resourceData && resourceData.length">
        <li v-for="(resource, key) in resourceData" :key="key">
          <a :href="resource.data.url.url" target="_blank">
            <strong>{{ resource.data.name }}</strong>
          </a>
        </li>
      </ul>
      <div v-else>
        <p>There are no resources at this time. Please check back soon.</p>
      </div>
      <div v-if="resourceContent && resourceContent.length > 5" class="more">
        <button type="button" @click="showMore" @keyUp="showMore">
          {{ more ? 'Show Less' : 'Show More' }}
        </button>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

export default {
  name: 'Resources',
  data() {
    return {
      more: false
    }
  },
  computed: {
    ...mapState({
      resources: state => state.content.resources,
      resourceContent: state => state.resources.content
    }),
    resourceData() {
      return this.resourceContent ? (this.more ? this.resourceContent : this.resourceContent.slice(0, 5)) : []
    }
  },
  created() {
    this.getResources(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('resources', ['getResources']),
    setHeight() {
      this.$utils.setHeight('#resources')
    },
    showMore() {
      this.more = !this.more
      this.setHeight()
    },
    toHtml(content) {
      return this.$utils.toHtml(content)
    }
  }
}
</script>

<style lang="stylus" scoped>
#resources
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0

    ul
      margin 0
      padding 0
      list-style-type none

      li
        margin-bottom 1em
        text-align center
</style>
