<template>
  <smooth-reflow v-if="about" id="about" v-resize:debounce="setHeight" tag="section">
    <figure v-if="about.image">
      <img :src="about.image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>About Us</h2>
      <div v-if="about.content" v-html="about.content"></div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'About',
  computed: {
    ...mapState('content', ['about'])
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    setHeight() {
      this.$utils.setHeight('#about')
    }
  }
}
</script>

<style lang="stylus" scoped>
#about
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0
</style>
