<template>
  <smooth-reflow id="home" tag="main">
    <above-fold></above-fold>
    <about></about>
    <action-alerts></action-alerts>
    <opportunities></opportunities>
    <resources></resources>
    <get-involved></get-involved>
    <partners></partners>
    <leadership></leadership>
    <contact></contact>
  </smooth-reflow>
</template>

<script>
import AboveFold from '../components/AboveFold.vue'
import About from '../components/About.vue'
import ActionAlerts from '../components/ActionAlerts.vue'
import Opportunities from '../components/Opportunities.vue'
import GetInvolved from '../components/GetInvolved.vue'
import Partners from '../components/Partners.vue'
import Resources from '../components/Resources.vue'
import Leadership from '../components/Leadership.vue'
import Contact from '../components/Contact.vue'

export default {
  name: 'Home',
  components: {
    AboveFold,
    About,
    ActionAlerts,
    GetInvolved,
    Leadership,
    Opportunities,
    Partners,
    Resources,
    Contact
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.hash) this.$scrollTo(this.$route.hash, { offset: '25vh' })
        const divs = [
          'about',
          'alerts',
          'opportunities',
          'contact',
          'get-involved',
          'partners',
          'leadership',
          'resources'
        ]
        divs.forEach(div => this.$utils.setHeight(`#${div}`))
      }, 1200)
    })
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.jewishearthalliance.org/'
        }
      ]
    }
  }
}
</script>
