import Prismic from 'prismic-javascript'
import PrismicDOM from 'prismic-dom'

const model = () => {
  return {
    about: null,
    alerts: null,
    current: null,
    getInvolved: null,
    loading: false,
    leadership: null,
    opportunities: null,
    partners: null,
    resources: null
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    SET_CONTENT(state, payload) {
      const newState = () => {
        return { ...payload, current: state.current, loading: state.loading }
      }
      Object.assign(state, newState())
    },
    SET_CURRENT(state, payload) {
      state.current = payload
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    }
  },
  actions: {
    async getContent({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.about) {
          const data = {}
          let content = (
            await vm.$prismic.client.query(Prismic.Predicates.any('document.tags', ['content']), { pageSize: 100 })
          ).results
          const intros = (await vm.$prismic.client.getSingle('intros')).data.body
          content = content.concat(intros)

          const sources = ['about', 'get_involved', 'alerts', 'opportunities', 'partners', 'resources', 'leadership']

          sources.forEach(source => {
            data[source] = content.filter(result => result.type === source || result.slice_type === source)[0]
          })

          const { about, get_involved: getInvolved, alerts, opportunities, partners, resources, leadership } = data

          const payload = {
            about: about
              ? {
                  image: about.data.image.url,
                  content: PrismicDOM.RichText.asHtml(about.data.content)
                }
              : null,
            getInvolved: getInvolved
              ? {
                  image: getInvolved.data.image.url,
                  content: PrismicDOM.RichText.asHtml(getInvolved.data.content)
                }
              : null,
            alerts: alerts
              ? {
                  image: alerts.primary.alerts_image.url,
                  content: PrismicDOM.RichText.asHtml(alerts.primary.alerts_intro)
                }
              : null,
            opportunities: opportunities
              ? {
                  image: opportunities.primary.opportunities_image.url,
                  content: PrismicDOM.RichText.asHtml(opportunities.primary.opportunities_intro)
                }
              : null,
            leadership: leadership
              ? {
                  image: leadership.primary.leadership_image.url,
                  content: PrismicDOM.RichText.asHtml(leadership.primary.leadership_intro)
                }
              : null,
            partners: partners
              ? {
                  image: partners.primary.partners_image.url,
                  content: PrismicDOM.RichText.asHtml(partners.primary.partners_intro)
                }
              : null,
            resources: resources
              ? {
                  image: resources.primary.resources_image.url,
                  content: PrismicDOM.RichText.asHtml(resources.primary.resources_intro)
                }
              : null
          }

          commit('SET_CONTENT', payload)
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    }
  },
  setCurrent({ commit }, payload) {
    commit('SET_CURRENT', payload)
  }
}
