<template>
  <smooth-reflow v-if="alerts" id="alerts" tag="section">
    <figure v-if="alerts && alerts.image">
      <img :src="alerts.image" alt="" />
    </figure>
    <div class="content">
      <h2>Action Alerts</h2>
      <div v-if="alerts && alerts.content" v-html="alerts.content"></div>
      <smooth-reflow v-if="alertData && alertData.length" tag="ul">
        <li v-for="(alert, key) in alertData" :key="key">
          <router-link :to="{ name: 'alerts', params: { id: alert.uid } }">
            <strong>{{ alert.data.title[0].text }}</strong>
          </router-link>
          <br />
          <small>{{ alert.data.date | date }}</small>
        </li>
      </smooth-reflow>
      <div v-if="!alertData.length">
        <p>There are no alerts at this time. Please check back soon.</p>
      </div>
      <div v-if="alertContent && alertContent.length > 5" class="more">
        <button data-micron="blink" type="button" @click="showMore" @keyUp="showMore">
          {{ more ? 'Show Less' : 'Show More' }}
        </button>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

export default {
  name: 'ActionAlerts',
  data() {
    return {
      more: false
    }
  },
  computed: {
    ...mapState({
      alerts: state => state.content.alerts,
      alertContent: state => state.alerts.content.sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
    }),
    alertData() {
      return this.alertContent ? (this.more ? this.alertContent : this.alertContent.slice(0, 5)) : []
    }
  },
  created() {
    this.getAlerts(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('alerts', ['getAlerts']),
    setHeight() {
      setTimeout(this.$utils.setHeight('#alerts'), 300)
    },
    showMore() {
      this.more = !this.more
    },
    toHtml(content) {
      return this.$utils.toHtml(content)
    }
  }
}
</script>

<style lang="stylus" scoped>
#alerts
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(-2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0

    ul
      margin 0
      padding 0
      list-style-type none

      li
        margin-bottom 1em
        text-align center
</style>
