<template>
  <smooth-reflow v-if="opportunities" id="opportunities" tag="section">
    <figure v-if="opportunities && opportunities.image">
      <img :src="opportunities.image" alt="" />
    </figure>
    <div class="content">
      <h2>Opportunities</h2>
      <div v-if="opportunities && opportunities.content" v-html="opportunities.content"></div>
      <smooth-reflow v-if="opportunityData && opportunityData.length" tag="ul">
        <li v-for="(opportunity, key) in opportunityData" :key="key">
          <router-link :to="{ name: 'opportunities', params: { id: opportunity.uid } }">
            <strong>{{ opportunity.data.title[0].text }}</strong>
          </router-link>
          <br />
          <small>{{ opportunity.data.date | date }}</small>
          <!-- <p>{{ opportunity.data.short_description[0].text | quote }}</p> -->
        </li>
      </smooth-reflow>
      <div v-if="!opportunityData.length">
        <p>There are no opportunities at this time. Please check back soon.</p>
      </div>
      <div v-if="opportunityContent && opportunityContent.length > 5" class="more">
        <button data-micron="blink" type="button" @click="showMore" @keyUp="showMore">
          {{ more ? 'Show Less' : 'Show More' }}
        </button>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

export default {
  name: 'Opportunities',
  data() {
    return {
      more: false
    }
  },
  computed: {
    ...mapState({
      opportunities: state => state.content.opportunities,
      opportunityContent: state =>
        state.opportunities.content.sort((a, b) => new Date(b.data.date) - new Date(a.data.date))
    }),
    opportunityData() {
      return this.opportunityContent ? (this.more ? this.opportunityContent : this.opportunityContent.slice(0, 5)) : []
    }
  },
  created() {
    this.getOpportunities(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('opportunities', ['getOpportunities']),
    setHeight() {
      setTimeout(this.$utils.setHeight('#opportunities'), 300)
    },
    showMore() {
      this.more = !this.more
    },
    toHtml(content) {
      return this.$utils.toHtml(content)
    }
  }
}
</script>

<style lang="stylus" scoped>
#opportunities
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(-2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0

    ul
      margin 0
      padding 0
      list-style-type none

      li
        margin-bottom 1em
        text-align center
</style>
