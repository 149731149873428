<template>
  <smooth-reflow tag="header">
    <div class="brand">
      <router-link :to="{ name: 'home' }" class="logo">
        <img :src="logo" alt="Jewish Earth Alliance" />
      </router-link>
      <h1>
        <router-link :to="{ name: 'home' }"> Jewish Earth Alliance </router-link>
      </h1>
    </div>
  </smooth-reflow>
</template>

<script>
import logo from '../assets/logos/jea-textless.png'

export default {
  name: 'AppHeader',
  data() {
    return {
      logo
    }
  }
}
</script>

<style lang="stylus" scoped>
header
  .brand
    display flex
    flex-direction row
    justify-content flex-start
    align-items center

  .logo
    padding 0.5em
    max-width 100px
    height auto

    @media screen and (min-width 800px)
      padding 1em
      max-width 120px

    img
      width 100%

  h1 a
    display none
    filter drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.5))
    color white
    text-transform uppercase
    font-size 0.8em

    @media screen and (min-width 800px)
      display block
</style>
