import PrismicDOM from 'prismic-dom'

const model = () => {
  return {
    content: null,
    endpoint: null,
    error: null,
    image: null,
    loading: false,
    thankYou: null
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    SET_CONTACT(state, payload) {
      const newState = () => {
        return { ...payload, loading: state.loading }
      }
      Object.assign(state, newState())
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    }
  },
  actions: {
    async getContact({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.endpoint) {
          const contact = (await vm.$prismic.client.getSingle('contact')).data
          commit('SET_CONTACT', {
            content: contact.content ? PrismicDOM.RichText.asHtml(contact.content) : null,
            endpoint: contact.endpoint ? contact.endpoint.url : null,
            error: contact.error ? PrismicDOM.RichText.asHtml(contact.error) : null,
            image: contact.image ? contact.image.url : null,
            thankYou: contact.thank_you ? PrismicDOM.RichText.asHtml(contact.thank_you) : null
          })
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    }
  }
}
