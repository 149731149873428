<template>
  <smooth-reflow v-if="getInvolved" id="get-involved" v-resize:debounce="setHeight" :tag="`section`">
    <figure v-if="getInvolved.image">
      <img :src="getInvolved.image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>Get Involved</h2>
      <div v-if="getInvolved.content" v-html="getInvolved.content"></div>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GetInvolved',
  computed: {
    ...mapState('content', ['getInvolved'])
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    setHeight() {
      this.$utils.setHeight('#get-involved')
    }
  }
}
</script>

<style lang="stylus" scoped>
#get-involved
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(-2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0
</style>
