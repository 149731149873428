import Prismic from 'prismic-javascript'
import { nameSort } from '../utils'

const model = () => {
  return {
    partners: [],
    allies: [],
    loading: false
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    ADD_CONTENT(state, payload) {
      if (payload.data && payload.data.length) payload.data.forEach(item => state[payload.name].push(item.data))
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    }
  },
  actions: {
    async getPartners({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.allies.length) {
          const allies = await vm.$prismic.client.query(Prismic.Predicates.any('document.type', ['allies']))
          commit('ADD_CONTENT', { name: 'allies', data: allies.results })
        }

        if (!state.partners.length) {
          const partners = await vm.$prismic.client.query(Prismic.Predicates.any('document.type', ['partners']), {
            pageSize: 1000
          })
          commit('ADD_CONTENT', { name: 'partners', data: partners.results })
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    }
  },
  getters: {
    partnerData(state) {
      return state.partners && state.partners.length ? [...state.partners].sort(nameSort) : null
    },
    allyData(state) {
      return state.allies && state.allies.length ? [...state.allies].sort(nameSort) : null
    }
  }
}
