<template>
  <smooth-reflow id="fourofour" tag="main">
    <div class="content">
      <h2>404</h2>
      <p>Sorry, page not found!</p>
      <p>
        <router-link :to="{ path: '/' }">Go To Homepage</router-link>
      </p>
    </div>
  </smooth-reflow>
</template>

<script>
export default {}
</script>

<style lang="stylus" scoped>
#fourofour
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  min-height 65vh
  width 100%
</style>
