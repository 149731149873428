<template>
  <smooth-reflow v-if="endpoint" id="contact" v-resize:debounce="setHeight" :tag="`section`">
    <figure v-if="image">
      <img :src="image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>Get In Touch</h2>
      <div v-if="content" v-html="content"></div>
      <contact-form :endpoint="endpoint" :error-msg="error" :thank-you="thankYou"></contact-form>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

import ContactForm from './ContactForm.vue'

export default {
  name: 'Contact',
  components: {
    ContactForm
  },
  computed: {
    ...mapState('contact', ['content', 'endpoint', 'image', 'error', 'thankYou'])
  },
  created() {
    this.getContact(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('contact', ['getContact']),
    setHeight() {
      this.$utils.setHeight('#contact')
    }
  }
}
</script>

<style lang="stylus" scoped>
#contact
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(-2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0
</style>
