<template>
  <smooth-reflow v-if="partners" id="partners" v-resize:debounce="setHeight" :tag="`section`">
    <figure v-if="partners.image">
      <img :src="partners.image" alt="" @onload="setHeight" />
    </figure>
    <div class="content">
      <h2>Partners</h2>
      <div v-if="partners.content" v-html="partners.content"></div>
      <partners-list :partners="data"></partners-list>
      <div class="more">
        <button data-micron="blink" type="button" @click="showMore" @keyUp="showMore">
          {{ more ? 'Show Less' : 'Show More' }}
        </button>
      </div>
      <h3>Allies</h3>
      <allies-list :allies="allyData"></allies-list>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

import AlliesList from './AlliesList.vue'
import PartnersList from './PartnersList.vue'

export default {
  name: 'Partners',
  components: {
    AlliesList,
    PartnersList
  },
  data() {
    return {
      more: false
    }
  },
  computed: {
    ...mapState('content', ['partners']),
    ...mapGetters('partners', ['partnerData', 'allyData']),
    data() {
      return this.partnerData ? (this.more ? this.partnerData : this.partnerData.slice(0, 3)) : []
    }
  },
  created() {
    this.getPartners(this)
  },
  mounted() {
    this.$nextTick(this.setHeight)
  },
  updated() {
    this.setHeight()
  },
  methods: {
    ...mapCacheActions('partners', ['getPartners']),
    showMore() {
      this.more = !this.more
    },
    setHeight() {
      this.$utils.setHeight('#partners')
    }
  }
}
</script>

<style lang="stylus" scoped>
#partners
  position relative
  display flex
  flex-direction column
  justify-content flex-start
  align-items center
  width 100%

  figure
    transform rotate(2.5deg)

  .content
    position absolute
    z-index 300
    margin 50% 0

    h3
      margin-bottom 1em
      padding-bottom 0.25em
      border-bottom 5px solid #319a46
      color #319a46
      text-align center
      text-transform uppercase

    .more
      display flex
      justify-content center
      align-items center
      margin-bottom 1em
      width 100%
      font-size 0.8em
</style>
