<template>
  <smooth-reflow tag="nav">
    <ul>
      <li v-for="(page, key) in pages" :key="key">
        <span @click="jumpTo(page.hash)" @keyUp="jumpTo(page.hash)">{{ page.name }}</span>
      </li>
    </ul>
  </smooth-reflow>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Navigation',
  data() {
    return {
      pages: [
        {
          name: 'About Us',
          hash: 'about'
        },
        {
          name: 'Action Alerts',
          hash: 'alerts'
        },
        {
          name: 'Opportunities',
          hash: 'opportunities'
        },
        {
          name: 'Resources',
          hash: 'resources'
        },
        {
          name: 'Get Involved',
          hash: 'get-involved'
        },
        {
          name: 'Partners',
          hash: 'partners'
        },
        {
          name: 'Leadership',
          hash: 'leadership'
        },
        {
          name: 'Get In Touch',
          hash: 'contact'
        }
      ]
    }
  },
  methods: {
    ...mapActions('ui', ['toggleNav']),
    jumpTo(hash) {
      if (this.$route.path !== '/') this.$router.push({ name: 'home', hash: `#${hash}` })
      this.$scrollTo(`#${hash}`)
      this.toggleNav()
    }
  }
}
</script>

<style lang="stylus" scoped>
nav
  position fixed
  top 0
  left 0
  z-index 1000
  display flex
  flex-direction column
  justify-content center
  align-items center
  padding 2em
  width 100vw
  height 100vh
  background #fff

  ul
    display flex
    flex-direction column
    justify-content center
    align-items center
    margin 0 auto
    margin-bottom 1em
    padding 0
    padding-bottom 0.25em
    list-style-type none

    li
      padding .5em
      width 100%
      color #3474d9
      text-align center
      font-weight bold
      font-size 1.2em
      cursor pointer

      span
        border-bottom 5px solid #3474d9

        &:hover
          border-bottom 5px solid #319a46
          color #319a46
</style>
