<template>
  <smooth-reflow id="contact-form" :tag="`form`">
    <div v-if="!loading && !submitted" class="fields">
      <fieldset>
        <label for="contact-name">
          <div class="field">Name <span class="label warning">Required</span></div>
          <input
            id="contact-name"
            v-model.trim="$v.formData.name.$model"
            type="text"
            name="Name"
            :class="{
              invalid: formData.name && $v.formData.name.$invalid
            }"
          />
          <span v-if="formData.name && $v.formData.name.$invalid" class="error-msg" role="status">
            Name must be at least five characters.
          </span>
        </label>
      </fieldset>

      <fieldset>
        <label for="contact-org">
          <div class="field">Organization</div>
          <input id="contact-org" type="text" name="Organization" />
        </label>
      </fieldset>

      <fieldset>
        <label for="contact-email">
          <div class="field">Email <span class="label warning">Required</span></div>
          <input
            id="contact-email"
            v-model.trim="$v.formData.email.$model"
            type="email"
            name="Email"
            :class="{
              invalid: formData.email && $v.formData.email.$invalid
            }"
          />
          <span v-if="formData.email && $v.formData.email.$invalid" class="error-msg" role="status">
            Must be valid email address.
          </span>
        </label>
      </fieldset>

      <fieldset>
        <label for="contact-phone">
          <div class="field">Telephone</div>
          <input id="contact-phone" v-model="formData.phone" v-mask="'(###) ###-####'" type="text" name="Phone" />
        </label>
      </fieldset>

      <fieldset>
        <label for="contact-zip">
          <div class="field">Zip Code</div>
          <input id="contact-phone" v-model="formData.zip" v-mask="'#####'" type="number" name="Zip" />
        </label>
      </fieldset>

      <fieldset>
        <label for="contact-msg">
          <div class="field">Your message <span class="label warning">Required</span></div>
          <textarea
            id="contact-msg"
            v-model.trim="$v.formData.msg.$model"
            name="Message"
            rows="8"
            placeholder="Ex.: Hello, I would like to join the Jewish Earth Alliance. Please send me more information."
            :class="{
              invalid: formData.msg && $v.formData.msg.$invalid
            }"
          ></textarea>
          <span v-if="formData.msg && $v.formData.msg.$invalid" class="error-msg" role="status">
            Message must be at least five characters.
          </span>
        </label>
      </fieldset>

      <fieldset class="checkboxes row">
        <label for="contact-newsletter">
          <input
            id="contact-newsletter"
            v-model="formData.newsletter"
            type="checkbox"
            name="Email Newsletter"
            checked
          />
          <div class="checkable">Receive email updates from the Jewish Earth Alliance</div>
        </label>

        <label for="contact-resources">
          <input id="contact-resources" v-model="formData.resources" type="checkbox" name="Resources" />
          <div class="checkable">Please send me resources to help organize my community</div>
        </label>

        <label for="contact-partner">
          <input id="contact-partner" v-model="formData.partner" type="checkbox" name="Partners" />
          <div class="checkable">My organization would like to partner with the Jewish Earth Alliance</div>
        </label>

        <label for="contact-advocacy">
          <input id="contact-advocacy" v-model="formData.advocacy" type="checkbox" name="Advocacy Days" />
          <div class="checkable">
            I live in the D.C. area and would like to participate in Advocacy Days on Capitol Hill
          </div>
        </label>
      </fieldset>

      <fieldset class="buttons row">
        <button
          id="submit"
          data-micron="blink"
          type="button"
          class="success"
          :disabled="$v.$invalid"
          @click.prevent="submit"
          @keyUp.prevent="submit"
        >
          Send
        </button>
        &nbsp;
        <button data-micron="shake" type="button" class="warning" @click.prevent="reset" @keyUp.prevent="reset">
          Reset
        </button>
      </fieldset>
    </div>

    <div v-if="loading" class="loading" role="status">
      <scale-loader color="#319a46" :width="2" :height="20"></scale-loader>
    </div>

    <div v-if="submitted">
      <div class="result" role="status">
        <div v-if="success" v-html="thankYou"></div>
        <div v-else>
          <div v-html="errorMsg"></div>
          <button data-micron="shake" type="button" class="warning" @click.prevent="reset" @keyUp.prevent="reset">
            Reset
          </button>
        </div>
      </div>
    </div>
  </smooth-reflow>
</template>

<script>
import { ScaleLoader } from '@saeris/vue-spinners'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'ContactForm',
  components: {
    ScaleLoader
  },
  mixins: [validationMixin],
  props: {
    endpoint: {
      type: String,
      default: ''
    },
    errorMsg: {
      type: String,
      default: ''
    },
    thankYou: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formData: {
        name: null,
        org: null,
        email: null,
        phone: null,
        msg: null,
        newsletter: true,
        resources: false,
        partner: false,
        advocacy: false
      },
      success: null,
      submitted: null,
      loading: false,
      recaptcha: null
    }
  },
  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(5)
      },
      email: {
        required,
        email
      },
      msg: {
        required,
        minLength: minLength(5)
      }
    }
  },
  computed: {
    error() {
      return this.$v.$anyError
    }
  },
  methods: {
    reset() {
      ;['name', 'email', 'phone', 'msg', 'newsletter', 'resources', 'partner', 'advocacy', 'zip'].forEach(field => {
        this.formData[field] = null
      })
      this.submitted = null
      document.getElementById('contact-form').reset()
      this.setHeight()
    },
    async submit() {
      try {
        this.loading = true
        this.setHeight()
        const res = await fetch(this.endpoint, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'manual',
          referrer: 'no-referrer',
          body: JSON.stringify({
            name: this.formData.name,
            organization: this.formData.org,
            email: this.formData.email,
            phone: this.formData.phone,
            zip: this.formData.zip,
            message: this.formData.msg,
            newsletter: this.formData.newsletter,
            resources: this.formData.resources,
            partner: this.formData.partner,
            advocacy: this.formData.advocacy
          })
        })
        const resParsed = await res.json()
        this.loading = false
        this.submitted = true
        this.success = resParsed && resParsed.success
        this.setHeight()
      } catch (err) {
        this.loading = false
        this.$toasted({ text: err.message, type: 'error' })
      }
    },
    setHeight() {
      this.$utils.setHeight('#contact')
    }
  }
}
</script>

<style lang="stylus" scoped>
#contact-form
  width 100%

  fieldset
    margin-bottom 1em

    label
      display flex
      flex-direction column
      margin-bottom 0.5em

      .field
        padding-bottom 0.5em

    &.row
      label
        flex-direction row
        justify-content flex-start
        align-items flex-start

        .field
          padding-bottom unset

  .loading
    display flex
    justify-content center
    align-items center
    padding 0
    width 100%

  .result
    text-align center

  .disabled
    opacity 0.5
    pointer-events none

  .invalid
    border-color red

  .error-msg
    color red
    font-size 0.8em

  .label
    margin-top -0.25em
    line-height 1.4em

  button.error
    background-color #ff0000
</style>
