import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import createCache from 'vuex-cache'

import alerts from './alerts'
import contact from './contact'
import content from './content'
import leadership from './leadership'
import opportunities from './opportunities'
import partners from './partners'
import resources from './resources'
import ui from './ui'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alerts,
    contact,
    content,
    leadership,
    opportunities,
    partners,
    resources,
    ui
  },
  plugins: [
    new VuexPersistence({
      key: 'jewishearthalliance',
      storage: window.sessionStorage
    }).plugin,
    createCache({ timeout: 1000 * 60 * 60 })
  ]
})
