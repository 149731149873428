import PrismicDOM from 'prismic-dom'
import fastdom from 'fastdom'
import micron from 'webkul-micron'

const nameSort = (itemA, itemB) => {
  const textA = itemA.name.toUpperCase()
  const textB = itemB.name.toUpperCase()
  return textA < textB ? -1 : textA > textB ? 1 : 0
}

const dateSort = (itemA, itemB) => {
  const textA = itemA.date
  const textB = itemB.date
  return textA < textB ? -1 : textA > textB ? 1 : 0
}

const setHeight = div => {
  setTimeout(() => {
    const parent = document.querySelector(`${div}`)
    const figure = document.querySelector(`${div} figure`)
    const content = document.querySelector(`${div} .content`)

    if (parent && figure && content) {
      let height = null

      fastdom.measure(() => {
        height = `${content.offsetHeight + figure.offsetHeight + (window.innerWidth > 500 ? 100 : 60)}px`
      })

      fastdom.mutate(() => {
        parent.style.height = height
      })
    }
  }, 200)
}

export default Vue => {
  Vue.prototype.$utils = {
    toHtml: PrismicDOM.RichText.asHtml,
    setHeight,
    fastdom,
    micron
  }
}

export { nameSort, dateSort }
