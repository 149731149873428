import Prismic from 'prismic-javascript'

const model = () => {
  return {
    content: [],
    loading: false
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    ADD_LEADERSHIP(state, payload) {
      if (payload.length) payload.forEach(item => state.content.push(item))
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    }
  },
  actions: {
    async getLeadership({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.content.length) {
          const leadership = await vm.$prismic.client.query(
            Prismic.Predicates.any('document.type', ['board_members']),
            { pageSize: 100 }
          )
          commit('ADD_LEADERSHIP', leadership.results)
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    }
  },
  getters: {
    leaderData(state) {
      return state.content.sort((a, b) => {
        return a.data.last_name > b.data.last_name ? 1 : -1
      })
    }
  }
}
