<template>
  <smooth-reflow id="app">
    <app-header v-if="$route.name !== 'home'"></app-header>
    <loading></loading>
    <nav-toggle></nav-toggle>
    <transition name="slideRight">
      <navigation v-if="showNav"></navigation>
    </transition>
    <transition name="fade">
      <router-view />
    </transition>
    <app-footer></app-footer>
  </smooth-reflow>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapCacheActions } from 'vuex-cache'

import Loading from './components/Loading.vue'
import NavToggle from './components/NavToggle.vue'
import AppHeader from './components/AppHeader.vue'
import Navigation from './components/Navigation.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    Loading,
    NavToggle,
    Navigation,
    AppHeader,
    AppFooter
  },
  computed: {
    ...mapState('ui', ['showNav', 'error'])
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'ui/SET_ERROR') {
        this.$toasted.error(this.error)
        setTimeout(() => this.clearError(), 5000)
      }
    })

    this.getUi(this)
    this.getContent(this)
  },
  methods: {
    ...mapCacheActions('ui', ['getUi']),
    ...mapCacheActions('content', ['getContent']),
    ...mapActions('ui', ['clearError'])
  }
}
</script>

<style lang="stylus">
@font-face
  font-weight 400
  font-style normal
  font-family 'BioRhyme'
  src local('BioRhyme'), local('BioRhyme-Regular'), url('https://fonts.gstatic.com/s/biorhyme/v4/1cXwaULHBpDMsHYW_ExBr1Sqg8is.woff2') format('woff2')
  font-display swap
  unicode-range U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD

@font-face
  font-weight 700
  font-style normal
  font-family 'BioRhyme'
  src local('BioRhyme Bold'), local('BioRhyme-Bold'), url('https://fonts.gstatic.com/s/biorhyme/v4/1cX3aULHBpDMsHYW_ET6ikGHocWG1-oG.woff2') format('woff2')
  font-display swap
  unicode-range U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD

#app
  overflow hidden
  max-width 100vw
  width 100%
  background-color #3474d9
  font-size 0.9em
  font-family 'BioRhyme', serif

  @media screen and (min-width 800px)
    font-size 1em

  main
    margin 0 auto
    max-width 960px
    width 100%

    figure
      position relative
      z-index 100
      max-width 100%
      width 100%

      img
        margin-bottom -0.4em
        width 100%
        height auto
        box-shadow 10px 10px 0px 0px rgba(0, 0, 0, 0.5)
        transform scale(1.2)

        @media screen and (min-width 500px)
          transform unset

    .content
      display flex
      flex-direction column
      justify-content center
      align-items center
      padding 1em 2em
      max-width 760px
      width 85%
      background #fff
      box-shadow 10px 10px 0px 0px #319a46

      @media screen and (min-width 800px)
        width 100%

      h2
        display inline-block
        margin-bottom 1em
        padding-bottom 0.25em
        border-bottom 5px solid #319a46
        color #319a46
        text-align center
        text-transform uppercase
        font-weight 700

      a:hover
        border-bottom 3px solid #3475d9

  footer
    a
      color #fff
      text-decoration underline
</style>
