import dayjs from 'dayjs'
import Prismic from 'prismic-javascript'

const model = () => {
  return {
    content: [],
    loading: false
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    ADD_ALERTS(state, payload) {
      payload.forEach(item => state.content.push(item))
    },
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    }
  },
  actions: {
    async getAlerts({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.content.length) {
          const alerts = await vm.$prismic.client.query(Prismic.Predicates.any('document.type', ['alerts']), {
            orderings: '[alerts.date desc]',
            pageSize: 100
          })
          const results = alerts.results.filter(result => {
            return !result.data.expires || dayjs(result.data.expires) > dayjs(new Date())
          })
          commit('ADD_ALERTS', results)
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    }
  }
}
