<template>
  <smooth-reflow id="above-fold" tag="section">
    <logo :width="logoWidth"></logo>
    <div class="cloud-wrap">
      <div class="clouds" :style="{ 'background-image': `url(${clouds})` }"></div>
    </div>
  </smooth-reflow>
</template>

<script>
import Logo from './Logo.vue'
import clouds from 'raw-loader!../assets/bgs/clouds.txt'

export default {
  name: 'AboveFold',
  components: {
    Logo
  },
  data() {
    return {
      clouds
    }
  },
  computed: {
    logoWidth() {
      return window.innerWidth < 500 ? '80%' : '60%'
    }
  }
}
</script>

<style lang="stylus" scoped>
#above-fold
  position relative
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin-bottom 4em
  max-width 100vw
  width 100%
  height 100vh

  @keyframes wrap
    0%
      transform translate3d(0, 0, 0)

    100%
      transform translate3d(-500vw, 0, 0)

  .cloud-wrap
    position absolute
    top 25vh
    z-index 1
    overflow hidden
    width 100vw
    height 50vh

  .clouds
    width 1000vw
    height 100%
    background-repeat repeat-x
    opacity 0.5
    animation wrap 600s linear infinite
</style>
