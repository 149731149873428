<template>
  <div v-show="isLoading" id="loading">
    <scale-loader color="#fff" :width="2" :height="20"></scale-loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ScaleLoader } from '@saeris/vue-spinners'

export default {
  name: 'Loading',
  components: {
    ScaleLoader
  },
  computed: {
    ...mapState({
      alertsLoading: state => state.alerts.loading,
      contactLoading: state => state.contact.loading,
      contentLoading: state => state.content.loading,
      oppsLoading: state => state.opportunities.loading,
      partnersLoading: state => state.partners.loading,
      resourcesLoading: state => state.resources.loading,
      uiLoading: state => state.ui.loading
    }),
    isLoading() {
      return (
        this.alertsLoading ||
        this.contactLoading ||
        this.contentLoading ||
        this.oppsLoading ||
        this.partnersLoading ||
        this.resourcesLoading ||
        this.uiLoading
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
#loading
  position fixed
  top 0
  left 0
  z-index 500
  display flex
  justify-content center
  align-items center
  width 100vw
  height 100vh
  pointer-events none
</style>
