<template>
  <div id="logo" :style="{ width }">
    <img :src="logo" alt="Jewish Earth Alliance logo" />
    <h1>Jewish Earth Alliance</h1>
    <div id="tagline">
      <img :src="tagline" alt="Calling On Congress To Act On Climate" />
    </div>
  </div>
</template>

<script>
import logoLg from '../assets/logos/jealogo-lg.png'
import logoMd from '../assets/logos/jealogo-md.png'
import tagline from '../assets/logos/jea-tagline.svg'

export default {
  name: 'Logo',
  props: {
    width: {
      type: String,
      default: '100%',
      required: false
    }
  },
  data() {
    return {
      logoLg,
      logoMd,
      tagline
    }
  },
  computed: {
    logo() {
      return window.innerWidth > 800 ? this.logoLg : this.logoMd
    }
  }
}
</script>

<style lang="stylus" scoped>
#logo
  z-index 2
  margin 0 auto
  padding-left 5%
  height auto

  img
    width 100%

  h1
    margin-left -200vw
    padding 0
    height 0

#tagline
  img
    width 100%
</style>
