import { InlineSvgPlugin } from 'vue-inline-svg'
import dayjs from 'dayjs'
import he from 'he'
import PrismicVue from 'prismic-vue'
import resize from 'vue-resize-directive'
import smartquotes from 'smart-quotes'
import Toasted from 'vue-toasted'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'

import SmoothReflow from '../components/SmoothReflow.vue'

export default Vue => {
  Vue.prototype.$bus = new Vue()

  Vue.component('SmoothReflow', SmoothReflow)

  Vue.directive('resize', resize)

  Vue.filter('quotes', str => {
    return smartquotes(he.decode(str))
  })

  Vue.filter('date', date => {
    return dayjs(date).format('MMMM DD, YYYY')
  })

  Vue.use(InlineSvgPlugin)
  Vue.use(Vuelidate)
  Vue.use(VueMask)
  Vue.use(VueMeta)
  Vue.use(VueScrollTo)

  Vue.use(Toasted, {
    position: 'bottom-right',
    duration: 3000
  })

  Vue.use(PrismicVue, {
    endpoint: 'https://jewish-earth-alliance.prismic.io/api/v2',
    linkResolver(doc) {
      switch (doc.type) {
        case 'alerts':
          return '/alerts'
        case 'opportunities':
          return '/opportunities'
      }
    }
  })
}
