import { render, staticRenderFns } from "./AlliesList.vue?vue&type=template&id=6cdef4ec&scoped=true&"
import script from "./AlliesList.vue?vue&type=script&lang=js&"
export * from "./AlliesList.vue?vue&type=script&lang=js&"
import style0 from "./AlliesList.vue?vue&type=style&index=0&id=6cdef4ec&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdef4ec",
  null
  
)

export default component.exports