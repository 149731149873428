const model = () => {
  return {
    error: null,
    loading: false,
    showNav: false,
    sitename: null,
    tagline: null,
    copyright: null,
    credits: null
  }
}

export default {
  namespaced: true,
  state() {
    return model()
  },
  mutations: {
    TOGGLE_LOADING(state) {
      state.loading = !state.loading
    },
    TOGGLE_NAV(state) {
      state.showNav = !state.showNav
    },
    SET_ERROR(state, payload) {
      state.error = payload
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
    SET_UI(state, payload) {
      const newState = () => {
        return {
          ...payload,
          error: state.error,
          loading: state.loading,
          showNav: state.showNav
        }
      }
      Object.assign(state, newState())
    }
  },
  actions: {
    async getUi({ commit, dispatch, state }, vm) {
      try {
        commit('TOGGLE_LOADING')
        if (!state.sitename) {
          const ui = await vm.$prismic.client.getSingle('ui')
          commit('SET_UI', ui.data)
        }
      } catch (err) {
        dispatch('ui/setError', err.message, { root: true })
      } finally {
        commit('TOGGLE_LOADING')
      }
    },
    loading({ commit }) {
      commit('TOGGLE_LOADING')
    },
    toggleNav({ commit }) {
      commit('TOGGLE_NAV')
    },
    setError({ commit }, payload) {
      commit('SET_ERROR', payload)
    },
    clearError({ commit }) {
      commit('CLEAR_ERROR')
    }
  }
}
