<template>
  <smooth-reflow v-if="leadership" class="leadership flex" tag="ul">
    <li v-for="(leader, key) in leadership" :key="key" class="full half-500 third-800 animated fadeIn">
      <picture>
        <img :src="leader.data.photo.url" :alt="leader.data.photo.caption" />
      </picture>
      <strong>{{ leader.data.first_name | quotes }} {{ leader.data.last_name | quotes }}</strong>
      <br />
      <em>{{ leader.data.role }}</em>
      <p>{{ leader.data.bio[0].text }}</p>
    </li>
  </smooth-reflow>
</template>

<script>
export default {
  name: 'LeadershipList',
  props: {
    leadership: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.leadership
  justify-content center
  align-items center
  margin 0
  padding 0
  list-style-type none

  &.flex
    margin 0
    padding 0
    width 100%

  li
    padding 0.5em
    text-align center

    picture
      height 250px
      width 250px
      margin 0 auto
      overflow hidden
      display flex
      align-items center
      justify-content center

      img
        border 0
        max-width 100%
        width auto

    .location
      font-size 0.7em
</style>
