<template>
  <smooth-reflow tag="footer">
    <h1 v-if="site_name">
      {{ site_name }}
    </h1>
    <div class="credit-line">
      <span v-if="copyright" class="copyright" v-html="copyrightFmtd"></span>
      <span v-if="credits" class="credits" v-html="creditsFmtd"></span>
    </div>
  </smooth-reflow>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AppFooter',
  computed: {
    ...mapState('ui', ['site_name', 'copyright', 'credits']),
    copyrightFmtd() {
      const date = new Date().getFullYear()
      return `&copy;${date} ${this.copyright}`
    },
    creditsFmtd() {
      return this.$utils.toHtml(this.credits)
    }
  }
}
</script>

<style lang="stylus" scoped>
footer
  display flex
  flex-direction column
  justify-content center
  align-items center
  margin-top 4em
  padding 1em
  min-height 20vh
  background-color rgba(0, 0, 0, 0.5)
  color #fff
  font-size 0.7em

  h1
    text-align center
    text-transform uppercase

    @media screen and (min-width 800px)
      padding 0

  .credit-line
    display flex
    flex-direction column
    justify-content center
    align-items center

    @media screen and (min-width 800px)
      flex-direction row

    span
      display inline-block
      padding 0 0.2em
</style>
